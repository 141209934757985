<template>
  <a-sub-menu :key="menuInfo.key" v-if="menuInfo.nav_type==='3'" v-bind="$props">
    <template #icon>
      <IconIndex :icon="menuInfo.icon" />
    </template>
    <template #title>{{ menuInfo.title }}</template>
    <template  v-for="(item, index) in menuInfo.children">
      <template v-if="item.nav_type == '3'">
        <template v-if="item.path">
          <a-menu-item :key="item.path ? item.path : index"
          :style="(collapsed && breakpoint) ? { display: 'none', left: '-200px', top: '-600px' } : {}">
          <layout-link :menu="item" :type="type"></layout-link>
        </a-menu-item>
          </template>
          <template v-else-if="!item.path">
            <sub-menu :key="item.path ? item.path : index" :menu-info="item" type="2" />
          </template>
      </template>
      <template v-else>
        <sub-menu :menu-info="item" :key="item.path ? item.path : index" />
      </template>
    </template>
  </a-sub-menu>
</template>
<script>
import { Menu } from 'ant-design-vue'
import layoutLink from '@/layout/layoutLink.vue'
import IconIndex from "@/layout/icon.vue";
import { DownOutlined } from "@ant-design/icons-vue"
export default {
  name: 'sub-menu',
  isSubMenu: true,
  components: { layoutLink, IconIndex, DownOutlined },
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
    type: '',
    collapsed: '',
    breakpoint: '',
  },
}
</script>