import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import { havePermission } from "@/utils/permission";
import { getToken } from "@/utils/auth";
import { getMenuTitle } from "@/utils";
import { isWxBrowser, randomString } from "@/utils/request";
import Vue from "vue";
import { createApp } from "vue";
import App from "../App.vue";
import store from "@/store";
import Layout from "@/layout/index.vue";
import { MenuTitle } from "@/utils"; // 菜单名
import { getSubMenu } from '@/views/topMenu/api'
import { getConfigData } from '../api/user'
import NProgress from "nprogress";
import VueRouter from "vue-router";
import home from "@/router/home"; // 工作台
import mail from "@/router/mail"; // 通讯录管理
import project from "@/router/project"; // 项目管理中心、项目库
import tasking from "@/router/tasking"; // 设计中心
import printorder from "@/router/printorder"; // 质量中心、图文中心
import auditorder from "@/router/auditorder"; // 文件中心、商业智能
import performance from "@/router/performance"; // 绩效中心
import appointprj from "@/router/appointprj"; // 计算中心、合同管理,外部协作
import finance from "@/router/finance";// 财务管理
import newsmanage from "@/router/newsmanage"; // 消息管理
import appcfg from "@/router/appcfg"; // 应用配置
import system from "@/router/system"// 系统管理
import top_menu from "@/router/top_menu"; // 横向菜单

createApp(App).use(VueRouter);

export const layoutRoutes = [
  ...home, // 工作台
  ...mail, // 通讯录管理
  ...project, // 项目管理中心、项目库
  ...tasking, // 设计中心
  ...printorder, // 质量中心、图文中心
  ...auditorder, // 审批中心
  ...performance, // 绩效中心
  ...appointprj, // 计算中心、合同管理、外部协作
  ...finance,//财务管理
  ...newsmanage, // 消息管理
  ...appcfg, // 应用配置
  ...system, // 系统管理
  top_menu, // 横向菜单
  {
    path: "/404",
    name: "404",
    component: (() => import("@/views/error-page/404.vue")),
    meta: { title: '暂无权限' },
  },
  {
    path: "/403",
    name: "error_403",
    component: (() => import("@/views/error-page/403.vue")),
    meta: { title: '暂无权限' },
  },
  {
    path: "/403_1",
    name: "error_403_1",
    component: (() => import("@/views/error-page/403_1.vue")),
    meta: { title: '暂无权限' },
  },
  {
    path: "/no-permission",
    name: "no-permission",
    component: (() => import("@/views/error-page/no-permission.vue")),
    meta: { title: '暂无权限' },
  },
  {
    path: "/refresh",
    name: "refresh",
    component: (() => import("@/layout/refresh.vue")),
    meta: { title: '刷新' },
  },
];

const routes = [
  {
    path: "/",
    // name: "login",
    component: (() => import("@/views/login/index.vue")),
    meta: { title: '登录页' },
  },
  {
    path: "/login",
    name: "login",
    component: (() => import("../views/login/index.vue")),
    meta: { title: '登录页' },
  },
  {
    path: "/",
    component: Layout,
    redirect: "/home/workspace",
    meta: { title: '工作台' },
    children: layoutRoutes,
  },
  { // 测试组件
    path: "/test",
    name: "test",
    component: (() => import("@/views/test/index.vue")),
    meta: { title: '测试' },
  },
  { // 数据可视化
    path: "/visualization/project",
    name: "visualization",
    component: (() => import("@/views/visuali/index.vue")),
    meta: { title: '数据可视化' },
  },
  { // webCad
    path: "/webCad",
    name: "webCad",
    component: (() => import("@/views/webCad/index.vue")),
    meta: { title: 'webCad' },
  },
  { // 企业微信登录
    path: "/wxbizlogin",
    name: "wxbizlogin",
    component: (() => import("@/views/login/wxlogin.vue")),
    meta: { title: '微信登录' },
  },
  { // 验证帐号
    path: "/verification",
    name: "verification",
    component: (() => import("@/views/login/verification.vue")),
    meta: { title: '修改密码' },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
    meta: { title: '' },
  },
];

const router = createRouter({
  mode: "history",
  // mode: "hash",
  base: process.env.BASE_URL,
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  // 动态更换图标、关键字
  // getConfigData().then((res)=>{
  //   if (result.base != undefined) {
  //     store.dispatch('layout/changeAppname', res.base.app_name)
  //     store.dispatch('layout/changeSiteicon', res.base.site_icon)
  //   }
  // }).catch(err=>{
  //   console.log(err);
  // })
  const changeFavicon = link => {
    let $favicon = document.querySelector('link[rel="icon"]');
    if ($favicon !== null) {
      $favicon.href = link;
    } else {
      $favicon = document.createElement("link");
      $favicon.rel = "icon";
      $favicon.href = link;
      document.head.appendChild($favicon);
    }
  }
  if (store.state.layout.siteicon != undefined) {
    changeFavicon(store.state.layout.siteicon);
  }
  if (MenuTitle(to.path) != undefined) {
    if (store.state.layout.appname == '' || store.state.layout.appname == undefined) {
      document.title = `${MenuTitle(to.path)}`
    } else {
      document.title = `${MenuTitle(to.path)} | ${store.state.layout.appname}`
    }
  } else {
    if (to.path.indexOf("/proj_home/") > -1) {
      getSubMenu('1',to.query.id).then(res => {
        if (store.state.layout.appname == '' || store.state.layout.appname == undefined) {
          document.title = `${MenuTitle(to.path, res)}`
        } else {
          document.title = `${MenuTitle(to.path, res)} | ${store.state.layout.appname}`
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      if (store.state.layout.appname == '' || store.state.layout.appname == undefined) {
        document.title = `${to.meta.title}`
      } else {
        document.title = `${to.meta.title} | ${store.state.layout.appname}`
      }
    }
  }
  store.dispatch("layout/changeTitle", "");
  if (document.title.length < 5) {
    to.meta.title = document.title
  } else {
    to.meta.title = document.title.slice(0,document.title.indexOf(' '))
  }
  // if (!to.meta.title && getMenuTitle(to.path) && store.state.user.menu) {
  //   to.meta.title = getMenuTitle(to.path);
  // }
  // 权限判断
  if (!havePermission(to.path)) {
    return next("/home/workspace/work");
  }
  if (document.title.indexOf('|') > -1) {
    to.meta.title = document.title.slice(0,document.title.indexOf(' '))
  } else {
    to.meta.title = document.title
  }
  if (to.meta.type != undefined) {
    if (to.meta.type != "prjspace" && to.meta.type != "none") {
      store.dispatch("layout/changeTitle", to.meta.title);
      store.dispatch("layout/changeType", to.meta.type);
    }
  } else if (to.meta.type == undefined) {
    store.dispatch("layout/changeTitle", to.meta.title);
    store.dispatch("layout/changeType", to.meta.type);
  } else if (getMenuTitle(to.path) && store.state.user.menu) {
    store.dispatch("layout/changeTitle", to.query.title);
  } else if (to.query.title) {
    store.dispatch("layout/changeTitle", getMenuTitle(to.path));
  }
  if (store.state.cache.cachedViews.includes(to.name)) {
    to.meta.keepalive = true;
  } else {
    to.meta.keepalive = false;
  }
  if (isWxBrowser()) {
    NProgress.start();
    let noLogin = process.env.VUE_APP_WXBIZ_NOLOGIN;
    // to.path != "/login" &&
    if (
      getToken() === "" &&
      to.path != "/wxbizlogin" &&
      to.query.loginstatus != 'logout' &&
      noLogin == "yes"
    ) {
      let path = to.path;
      localStorage.setItem("query", JSON.stringify({ path, ...to.query }));
      let appid = process.env.VUE_APP_WXBIZ_CORPID;
      let redirect_uri = encodeURIComponent(
        process.env.VUE_APP_WXBIZ_LOGIN_RTNURI
      );
      let scope = "snsapi_base";
      let state = randomString();
      let login_url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
      window.location.href = login_url;
    } else if (getToken() !== '' && to.path == "/login" && noLogin == "yes") {
      return next("/home/workspace/work");
    }
    else {
      next();
    }
  } else if (to.path == "/verification") {
    next();
  } else {
    if (to.path.indexOf("login") < 0) {
      // 每次切换页面时，调用进度条
      let _login_nm = store.state.user.login_nm;
      if (JSON.parse(localStorage.getItem("user")) == null) {
        next(`/login?redirect=${to.fullPath}`);
      } else {
        let _login_nms = JSON.parse(localStorage.getItem("user")).login_nm;
        let loginForm = JSON.parse(localStorage.getItem("user"));
        if (_login_nm != _login_nms) {
          store.dispatch("user/login", loginForm.token);
        }
      }
      NProgress.start();
    }
    if (getToken() === "" && to.path == "/wxbizlogin") {
      next();
    } else if (getToken() === "" && to.path !== "/login") {
      // message.error("登陆失效或未登录，请登陆重试！", 4);
      // next(`/login?redirect=${to.fullPath}`);
      next();
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done();
});

export default router;
