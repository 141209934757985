import { $get } from '@/utils/request'
import { reactive } from 'vue';

export const formItemLayout = {
    labelCol: {
        xxl: { span: 6 },
        xl: { span: 6 },
        lg: { span: 6 },
        md: { span: 6 },
        sm: { span: 6 },
        xs: { span: 0 },
    },
    wrapperCol: {
        xxl: { span: 15 },
        xl: { span: 15 },
        lg: { span: 15 },
        md: { span: 15 },
        sm: { span: 15 },
        xs: { span: 1 },
    },
};

export const formItemLayouts = {
    labelCol: {
        xxl: { span: 6 },
        xl: { span: 6 },
        lg: { span: 6 },
        md: { span: 6 },
        sm: { span: 6 },
        xs: { span: 0 },
    },
    wrapperCol: {
        xxl: { span: 15 },
        xl: { span: 15 },
        lg: { span: 15 },
        md: { span: 15 },
        sm: { span: 15 },
        xs: { span: 1 },
    },
};

export const formQueryItemLayout = {
    labelCol: {
        xxl: { span: 0 },
        xl: { span: 0 },
        lg: { span: 0 },
        md: { span: 6 },
        sm: { span: 7 },
        xs: { span: 12 },
    },
    wrapperCol: {
        xxl: { span: 24 },
        xl: { span: 24 },
        lg: { span: 24 },
        md: { span: 16 },
        sm: { span: 16 },
        xs: { span: 16 },
    },
};

export function filter(inputValue, path) { // 地点搜索
    return path.some(
        (option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    )
};

export const getstatus = function (id, sub_id, major_id) { // 获取状态
    return new Promise((resolve) => {
        $get({
            url: "details/proj_details",
            params: {
                proj_id: id,
                proj_sub_id: sub_id,
                major_id: major_id,
            }
        }).then(res => {
            resolve(res.data.project_info[0].status);
        }).catch(err => {
            message.error(err, 4);
        })
    })
};

export const numberToCurrencyNo = function (num) {
    if (!num) return '0.00'
    let value = (num - 0).toFixed(2).toString();
    // 获取整数部分
    const intPart = Math.trunc(value)
    // 整数部分处理，增加,
    const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    // 预定义小数部分
    let floatPart = ''
    // 将数值截取为小数部分和整数部分
    const valueArray = value.toString().split('.')
    if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
    }
    return intPartFormat + floatPart
};
export const pro_table = [
    {
        width: 16,
    },
    {
        width:'20%',
        title: "产品名称",
        dataIndex: "pro_nm",
        ellipsis: true,
    },    {
        width:'16%',
        title: "单位",
        dataIndex: "pro_unit_nm",
        ellipsis: true,
    },    
    {
        width:'16%',
        title: "数量",
        dataIndex: "qty",
        ellipsis: true,
    },    
    {
        width:'16%',
        title: "单价",
        dataIndex: "unit_price",
        ellipsis: true,
    },    
    {
        width:'16%',
        title: "总价",
        dataIndex: "total_price",
        ellipsis: true,
    },    
    {
        title: "操作",
        dataIndex: "operate",
        ellipsis: true,
    },
]

export const file_table = [ // 文件
    {
        width: 16,
    },
    {
        title: "文件ID",
        dataIndex: "rsc_id",
        ellipsis: true,
    },
    {
        title: "名称",
        dataIndex: "file_nm",
        ellipsis: true,
    },
    {
        title: "类型",
        dataIndex: "file_type_lbl",
        ellipsis: true,
    },
    {
        title: "标签",
        dataIndex: "file_label",
        ellipsis: true,
    },
    {
        title: "大小",
        dataIndex: "size",
        ellipsis: true,
    },
    {
        title: "备注",
        dataIndex: "cmt",
        ellipsis: true,
    },
    {
        title: "创建人",
        dataIndex: "creator",
        ellipsis: true,
    },
    {
        title: "创建时间",
        dataIndex: "created",
        ellipsis: true,
        sorter: (a, b) => {
            return a.created > b.created ? 1 : -1;
        },
    },
    {
        title: "操作",
        dataIndex: "operation",
        fixed: "right",
    },
];

export const file_typ_8 = [
    {
        width: 16,
    },
    {
        title: "文件ID",
        dataIndex: "rsc_id",
        ellipsis: true,
    },
    {
        title: "名称",
        dataIndex: "file_nm",
        ellipsis: true,
    },
    {
        title: "类型",
        dataIndex: "file_type_lbl",
        ellipsis: true,
    },
    {
        title: "标签",
        dataIndex: "file_label",
        ellipsis: true,
    },
    {
        title: "大小",
        dataIndex: "size",
        ellipsis: true,
    },
    {
        title: "创建人",
        dataIndex: "creator",
        ellipsis: true,
    },
    {
        title: "创建时间",
        dataIndex: "created",
        ellipsis: true,
        sorter: (a, b) => {
            return a.created > b.created ? 1 : -1;
        },
    },
    {
        title: "操作",
        dataIndex: "operation",
        fixed: "right",
    },
];

export const file_newModal = [
    {
        width: 16,
    },
    {
        title: "名称",
        dataIndex: "file_nm",
        ellipsis: true,
    },
    // {
    //     title: "文件类型",
    //     dataIndex: "",
    //     ellipsis: true,
    // },
    {
        title: "标签",
        dataIndex: "file_label",
        ellipsis: true,
    },
    {
        title: "大小",
        dataIndex: "size",
        ellipsis: true,
    },
    {
        title: "操作",
        dataIndex: "operation",
        fixed: "right",
    },
];

export const GloTips = async (_rule, value) => {
    if (value === "") {
        return Promise.reject('该项不能为空');
    } else {
        return Promise.resolve();
    }
};

export const GloPrint = (rule, value, callback) => {
    var patrn = /^[0-9]*$/
    if (value === "") {
        return Promise.reject("该项不能为空");
    } else if (!patrn.test(value)) {
        return Promise.reject("请输入纯数字");
    } else {
        return Promise.resolve();
    }
};

export const DeleteTips = (rule, value, callback) => {
    if (value === "") {
        return Promise.reject("该项不能为空");
    } else if (value != "删除角色") {
        return Promise.reject("输入内容有误，请重新输入");
    } else {
        return Promise.resolve();
    }
};

export const phoneTips = (rule, value, callback) => {
    let reg = /^1[3456789]\d{9}$/;
    if (value === "") {
        return Promise.reject("该项不能为空");
    } else if (!reg.test(value)) {
        return Promise.reject("请输入正确的手机号");
    } else {
        return Promise.resolve();
    }
};

export const select_GloTips = (rule, value, callback) => {
    if (value === '' || value === null || value === undefined || value.length == 0) {
        return Promise.reject("该项不能为空");
    } else {
        return Promise.resolve();
    }
};

export const verkeyCode = (rule, value, callback) => {
    if (value === "") {
        return Promise.reject("该项不能为空");
    } else if (value.length != 6) {
        return Promise.reject("验证码格式为6位数");
    } else {
        return Promise.resolve();
    }
};

export const build_Inputs = (rule, value) => {
    if (value[0].value === '') {
        return Promise.reject("该项不能为空")
    } else {
        return Promise.resolve();
    }
};

export const IsTime = (rule, value) => {// 项目日程校验时间
    if (value === "" || value === undefined) {
        return Promise.reject("该项不能为空");
    } else {
        return Promise.resolve();
    }
};

export const column = {
    xxl: 4,
    xl: 4,
    lg: 3,
    md: 3,
    sm: 2,
    xs: 1
};

export const rules = reactive({
    title: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    pro_nm:[
        {
            required: true,
            validator: select_GloTips,
        },
    ],
    pro_unit_nm:[
        {
            required: true,
            validator: select_GloTips,
        },
    ],
    qty: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    unit_price: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    src: [
        {
            required: true,
            validator: select_GloTips,
        },
    ],
    dept_id: [
        {
            required: true,
            validator: select_GloTips,
        },
    ],
    typ: [
        {
            required: true,
            validator: select_GloTips,
        },
    ],
    audit: [
        {
            required: true,
            validator: select_GloTips,
        },
    ],

    stand_nm: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    proj_maint_id: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    file_nm: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    specs: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    print_qty: [
        {
            required: true,
            validator: GloPrint,
        },
    ],
    file_source: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    // 
    proj_id: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    // 项目事件
    proj_nm: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    event_typ: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    event_nm: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    event_path: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    // 直接指派
    mbr: [
        {
            required: true,
            validator: GloTips,
            trigger: "change",
        },
    ],
    dep: [
        {
            required: true,
            validator: GloTips,
            trigger: "Change",
        },
    ],
    values: [
        {
            required: true,
            validator: GloTips,
            trigger: "Change",
        },
    ],
    audit_id: [ // 关联内审
        {
            required: true,
            validator: GloTips,
        },
    ],

    // 系统角色
    un: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    lbl: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    change_role_id: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    delete: [
        {
            required: true,
            validator: DeleteTips,
        },
    ],
    o: [
        {
            required: true,
            validator: GloPrint,
        },
    ],

    // 考核任务
    asst_cycle: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    clo_time: [
        {
            required: true,
            validator: GloTips,
        },
    ],
    //公司图审
    user_id: [
        {
            required: true,
            validator: select_GloTips,
        },
    ],
});

export const projedit_table = [
    {
        width: 16,
    },
    {
        title: "专业",
        dataIndex: "major_nm",
        // width: 150,
        ellipsis: true,
    },
    {
        title: "部门",
        dataIndex: "dept_nm",
        // width: 150,
        ellipsis: true,
    },
    {
        title: "是否主设",
        dataIndex: "is_main",
        // width: 300,
        ellipsis: true,
    },
    {
        title: "项目分工",
        dataIndex: "cmt",
        // width: 300,
        ellipsis: true,
    },
    {
        title: "操作人",
        dataIndex: "updator",
        ellipsis: true,
    },
    {
        title: "操作时间",
        dataIndex: "updated",
        ellipsis: true,
        sorter: (a, b) => {
            return a.updated > b.updated ? 1 : -1;
        },
    },
    {
        title: "操作",
        dataIndex: "operation",
        width: 100,
        fixed: "right",
    },
];

export const file_less = [ // 文件校验
    'dwg', 'jpg', 'png', 'jpeg', 'gif', 'doc', 'docx', 'pdf', 'ai', 'ppt', 'cdr', 'pptx', 'xlsx', 'xls', 'swf', 'txt', 'psd', 'apl', 'rar', 'zip', 'cui', 'shx', 'pat', 'mns', 'mnl', 'mnc', 'mnu', 'dwt', 'dwg', 'dxf', 'dwf', 'pcp', 'pc3', 'hdi', 'plt', 'las', 'lisp', 'bak', 'scr', 'lin', 'ctb', 'stb'
];

export const avactor_less = [ // 文件校验--头像
    'jpg', 'png', 'jpeg', 'gif'
];