<template>
  <a-layout id="layout">
    <a-layout-sider ref="siderWidth" v-model:collapsed="collapsed" breakpoint="md" :trigger="null"
      :collapsed-width="breakpoint ? 0 : 60" @breakpoint="onBreakpoint" collapsible :inline-collapsed="collapsed"
      :style="{ background: '#fff', overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }">
      <div class="logo">
        <img :hidden="collapsed && breakpoint" :src="infoForm.site_icon == '' ? Favicon : infoForm.site_icon" />
        <h1 :hidden="collapsed">{{ infoForm.app_name == '' ? '中南幕墙' : infoForm.app_name }}</h1>
      </div>
      <div class="menu-box">
        <a-menu mode="inline" theme="dark" v-model:selectedKeys="selectedKeys" :default-selected-keys="[$route.path]"
          :inline-index="-10" v-model:openKeys="openKeys" @openChange="onOpenChange">
          <template v-for="(item, index) in $store.state.user.menu">
            <template v-if="!item.children && item.hidden === 'f'">
              <a-menu-item :key="item.path ? item.path : index">
                <template #icon>
                  <HomeOutlined />
                </template>
                <layout-link :menu="item"></layout-link>
              </a-menu-item>
            </template>
            <template v-else>
              <sub-menu :key="item.path ? item.path : index" :menu-info="item" :collapsed="collapsed"
                :breakpoint="breakpoint" />
            </template>
          </template>
        </a-menu>
      </div>
    </a-layout-sider>
    <a-layout :style="{ marginLeft: collapsed ? (breakpoint ? '' : '60px') : '200px' }">
      <a-layout-header theme="dark">
        <!-- :style="{ position: 'fixed', top: 0, right: 0, zIndex: 999, overflow: 'hidden', width: `${(headerWidth - 0) - (collapsed ? (breakpoint ? 0 : 60) : 200)}px` }"> -->
        <div class="layout-header"
          :style="{ position: 'fixed', top: 0, right: 0, left: `${collapsed ? (breakpoint ? 0 : 60) : 200}px`, zIndex: 999, overflow: 'hidden', }">
          <div class="layout-header-left">
            <MenuFoldOutlined v-if="!collapsed" class="trigger" @click="() => { collapsed = !collapsed }" />
            <MenuUnfoldOutlined v-else class="trigger" @click="() => { collapsed = !collapsed }" />
            <!-- <RedoOutlined :class="refreshAnimate ? 'refresh-animate' : ''" @click.stop="refresh()" /> -->
          </div>
          <div class="layout-header-right" v-if="!breakpoint || collapsed">
            <a-badge :count="0">
              <a-tooltip placement="left">
                <template #title>首页</template>
                <HomeOutlined @click="$router.push({ name: 'work' })" style="color: rgba(0, 0, 0, 0.65)" />
              </a-tooltip>
            </a-badge>
            <a-badge :count="count" :offset="['-3px']" id="news_icon" v-on:click="handlerbell_message(bellModal)">
              <a-tooltip placement="left">
                <template #title>消息</template>
                <BellOutlined style="color: rgba(0, 0, 0, 0.65)" />
              </a-tooltip>
            </a-badge>
            <a-badge :count="0">
              <a-tooltip placement="left">
                <template #title>个人设置</template>
                <SettingOutlined @click="$router.push({ name: 'setup' })"
                  :style="{ marginRight: '24px', color: 'rgba(0, 0, 0, 0.65)' }" />
              </a-tooltip>
            </a-badge>
            <!-- 头像 -->
            <a-dropdown placement="bottomRight" :trigger="['click']" style="cursor: pointer">
              <div>
                <MbrAvatar :user_sex="$store.state.user.gender" :user_nm="$store.state.user.name"
                  :avatar="$store.state.user.avatar" />
                <span :style="{ marginLeft: '8px' }">{{ $store.state.user.name }}</span>
              </div>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="logout">
                    <LogoutOutlined />&nbsp;&nbsp;退出登录
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
        <!-- 消息 -->
        <div v-if="false" id="bellDiv" class="bell_box"
          :style="{ height: (news_bell == '' ? 'auto' : `${headerHeight - 48}px`) }">
          <div class="title_span" @click.stop>消息</div>
          <a-empty v-if="news_bell == ''" @click.stop class="bell_empty">
            <template #description>暂无未读消息</template>
          </a-empty>
          <div v-else :style="{ height: `${headerHeight - 48 - 51 - 64}px`, overflow: 'auto' }">
            <a-list item-layout="horizontal" :data-source="news_bell_list">
              <template #renderItem="{ item }">
                <a-list-item @click.stop="handlerInfomation()" class="cursor">
                  <!-- <a-list-item-meta @click="handlerNewsJump(item)"> -->
                  <a-list-item-meta>
                    <template #title>
                      <!-- <a-tag style="font-size: 14px">{{ item.payload.title }}</a-tag> -->
                      <a-tooltip placement="top">
                        <template #title>置为已读</template>
                        <EyeOutlined @click.stop="handlerClick('1', item.msg_id)" style="font-size: 14px; float: right" />
                      </a-tooltip>
                    </template>
                    <template #description>
                      <!-- <div style="margin-top: 16px; white-space: break-spaces;"> -->
                      <!-- <span style="font-size: 13px;">
                            {{ item.payload.message }}
                          </span>
                          <br />
                          <div style="font-size: 12px; color: #00000073; margin-top: 8px ">
                            {{ item.sent_at }}
                          </div> -->
                      <div v-html="item.payload.message"></div>
                      <!-- </div> -->
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </template>
              <a-divider style="margin:36px 0 0 " />
              <a-list-item @click="handlerMoreInfomation" class="cursor">
                <a-button style="margin: auto">浏览更多...</a-button>
              </a-list-item>
            </a-list>
          </div>
          <div class="bell_bottom">
            <a class="bell_bottom_span" @click.stop="handlerClick('2')">
              全部设为已读</a>
            <a @click="$router.push({ name: 'home_news' })" class="bell_bottom_span">查看更多</a>
          </div>
        </div>
        <a-drawer :style="{ marginTop: '48px' }" v-model:visible="bellModal" :footer="null" :closable="false"
          @close="() => { bellModal = false; }" destroyOnClose :bodyStyle="bodyStyles" width="420px">
          <a-affix :offset-top="0.01" style="width: 100%">
            <div class="detail_page_header">
              <a-page-header :ghost="false">
                <template #title>
                  <div class="title_span"> 消息</div>
                </template>
                <template #extra>
                  <a-tooltip style="margin-left: 10px;" placement="top">
                    <template #title>关闭</template>
                    <close-outlined @click="() => { bellModal = false; }"
                      style="font-size: 16px; float: right;margin-top: 10px;" />
                  </a-tooltip>
                  <a-tooltip placement="top">
                    <template #title>全部已读</template>
                    <EyeOutlined @click.stop="handlerClick('2')"
                      style="font-size: 18px; float: right;margin-top: 10px;margin-right: 15px;" />
                  </a-tooltip>
                </template>
              </a-page-header>
            </div>
          </a-affix>
          <a-empty style="margin-top:16px" v-if="news_bell == ''" @click.stop class="bell_empty">
            <template #description>暂无未读消息</template>
          </a-empty>
          <div v-if="!news_bell == ''" :style="{ height: `${headerHeight - 48 - 51 - 34}px`, overflow: 'auto', }">
            <a-list item-layout="horizontal" :data-source="news_bell_list">
              <template #renderItem="{ item }">
                <a-list-item class="list-item">
                  <a-list-item-meta>
                    <template #title>
                      <div style="margin:0 36px auto;font-size: 10px;color: rgba(94,94,94 ,0.5); text-align: center;">
                        {{ item.sent_at }}</div>
                    </template>
                    <template #description>
                      <div class="flexbox" :style="listStyle" v-html="getItem(item.payload.message, item.payload.title)">
                      </div>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </template>
              <a-divider style="margin:36px 0 0 " />
              <a-list-item @click="handlerMoreInfomation" class="cursor">
                <a-button style="margin: auto">浏览更多...</a-button>
              </a-list-item>
            </a-list>
          </div>
        </a-drawer>
        <a-page-header :title="$store.state.layout.title"
          :style="{ position: 'fixed', top: '48px', right: 0, left: `${collapsed ? (breakpoint ? 0 : 60) : 200}px`, zIndex: 99, overflow: 'hidden', }"
          v-if="(['none', 'workspace', 'prjspace'].indexOf($store.state.layout.type) < 0) || $store.state.layout.type == undefined"
          :backIcon="false" class="layout-subheader">
          <template #footer>
            <template v-if="$store.state.layout.type == 'mail_dept'">
              <div style="margin-top: 0">
                <a-tabs v-model:activeKey="$store.state.layout.newskey" @change="handlerMailDept">
                  <a-tab-pane v-if="power.mail_dept_list" key="1" tab="主数据" />
                  <a-tab-pane v-if="power.mail_dept_weixi" key="2" tab="企业微信" />
                </a-tabs>
              </div>
            </template>
            <template v-if="$store.state.layout.type == 'mail_mbr'">
              <div style="margin-top: 0">
                <a-tabs v-model:activeKey="$store.state.layout.newskey" @change="handlerMailMbr">
                  <a-tab-pane v-if="power.mail_mem_list" key="1" tab="主数据" />
                  <a-tab-pane v-if="power.mail_mem_weixin" key="2" tab="企业微信" />
                </a-tabs>
              </div>
            </template>
            <template v-if="$store.state.layout.type == 'auditorder'">
              <div style="margin-top: 0">
                <a-tabs v-model:activeKey="$store.state.layout.newskey" @change="handlerQuality">
                  <a-tab-pane v-if="power.quality_manage_initiate" key="1" tab="我发起的图审单" />
                  <a-tab-pane v-if="power.quality_manage_check" key="2" tab="我校对的图审单" />
                  <a-tab-pane v-if="power.quality_manage_assign" key="3" tab="我指派的图审单" />
                  <a-tab-pane v-if="power.quality_manage_review" key="4" tab="我审核的图审单" />
                  <a-tab-pane v-if="power.quality_manage_seal" key="5" tab="图纸盖章" />
                </a-tabs>
              </div>
            </template>
            <template v-if="$store.state.layout.type == 'filemgm'">
              <div style="margin-top: 0">
                <a-tabs v-model:activeKey="$store.state.layout.newskey" @change="handlerFilemgm">
                  <a-tab-pane v-if="power.file_list_list" key="1" tab="文件列表" />
                  <a-tab-pane v-if="power.file_list_template" key="2" tab="文件模板" />
                </a-tabs>
              </div>
            </template>
            <template v-if="$store.state.layout.type == 'workbench_config'">
              <a-tabs v-model:activeKey="$store.state.layout.newskey" @change="handlerWorkbench">
                <a-tab-pane v-if="power.config_word_pends" key="1" tab="快捷方式" />
                <a-tab-pane v-if="power.config_word_shortcuts" key="2" tab="待办事项" />
              </a-tabs>
            </template>
            <template v-if="$store.state.layout.type == 'newsmanage'">
              <div style="margin-top: 0">
                <a-tabs v-model:activeKey="$store.state.layout.newskey" @change="hangdlerNewsmanage">
                  <a-tab-pane v-if="power.msg_mgmt_scene" key="1" tab="消息场景" />
                </a-tabs>
              </div>
            </template>
          </template>
        </a-page-header>
        <a-page-header :title="$store.state.layout.title"
          :style="{ position: 'fixed', top: '48px', zIndex: 99, width: '100%' }" v-else :backIcon="false"
          class="layout-subheader">
        </a-page-header>
      </a-layout-header>
      <a-layout-content :style="{ overflow: 'auto', boxSizing: 'border-box' }">
        <a-back-top :visibilityHeight="0" />
        <!-- <div v-if="$store.state.layout.type == 'workspace'" style="height: 183px"></div> -->
        <div v-if="$store.state.layout.type == 'none'" style="height: 48px"></div>
        <div
          v-else-if="['mail_dept', 'mail_mbr', 'companyindex', 'auditorder', 'contractprj', 'performance', 'clientList', 'clientsettle', 'filemgm', 'business', 'appcfg', 'newsmanage', 'system', 'workbench_config'].indexOf($store.state.layout.type) > -1"
          style="height: 161px"></div>
        <div v-else-if="['prjspace', 'workspace'].indexOf($store.state.layout.type) < 0" style="height: 123px"></div>
        <keep-alive>
          <router-view v-if="$route.meta.keepalive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepalive" :key="$route.fullPath" />
      </a-layout-content>
      <a-layout-footer style="text-align: center; margin-top: 60px">
        <!-- {{ $store.state.layout.type }} -->
        <!-- copyright&nbsp;&nbsp;©&nbsp;&nbsp;{{ dataTime }}&nbsp;&nbsp;{{ infoForm.app_name }}
          浙江中南幕墙设计研究院 -->
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>
import { reactive, ref, toRefs, watch } from 'vue';
import subMenu from "@/layout/subMenu.vue";
import layoutLink from "@/layout/layoutLink.vue";
import { find, findIndex, remove } from "lodash";
import { emitter } from "@/utils";
import { getUserInfo } from "@/api/user";
import { $get, $post, baseURL } from "@/utils/request";
import { column } from "@/views/components/api/api";
import Favicon from "@/assets/favicon.png";
import store from "@/store";
import { notification } from "ant-design-vue";
import { MenuKey } from "@/utils";
import {
  BellOutlined, SettingOutlined, HomeOutlined,
  RedoOutlined, MenuFoldOutlined, MenuUnfoldOutlined,
  EyeOutlined, EditOutlined, LogoutOutlined, CloseOutlined
} from "@ant-design/icons-vue";
import SwitchProj from "@/views/components/SwitchProj.vue";
import { message } from "ant-design-vue";
import { hasAction } from '@/utils'
import MbrAvatar from '@/views/components/mbravatar.vue'
import MarkdownIt from 'markdown-it'
import hljs from 'highlight.js'
const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
  breaks: true,
  xhtmlOut: false,        // 使用 '/' 来闭合单标签 （比如 <br />）。 这个选项只对完全的 CommonMark 模式兼容。
  langPrefix: 'language-',  // 给围栏代码块的 CSS 语言前缀。对于额外的高亮代码非常有用。
  quotes: '“”‘’',
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(lang, str).value;
      } catch (__) { }
    }

    return ''; // 使用额外的默认转义
  }
})
export default {
  name: "layout",
  components: {
    subMenu, layoutLink, SwitchProj, MbrAvatar,
    BellOutlined, SettingOutlined, HomeOutlined, RedoOutlined,
    MenuFoldOutlined, MenuUnfoldOutlined, EyeOutlined, EditOutlined,
    LogoutOutlined, CloseOutlined
  },
  data() {
    const state = reactive({
    });
    return {
      bodyStyles: {
        background: '#f0f2f5',
        height: '800px',
        overflow: 'auto',
        padding: 0,
      },
      listStyle: {
        background: "#fff",
        width: "94%",
        margin: "0 auto",
        marginBottom: "0px",
        borderRadius: "15px",
        padding: "20px"
      },
      Favicon,
      ...toRefs(state),
      headerWidth: document.documentElement.clientWidth,
      headerHeight: document.body.clientHeight,
      selectedKeys: [this.$route.path, this.$route.meta.activeMenuPath ? this.$route.meta.activeMenuPath : ''],
      openKeys: ref([""]),
      dataTime: new Date().getFullYear(),
      history: [],
      collapsed: this.headerWidth >= 768 ? false : true,
      breakpoint: false,
      refreshAnimate: false,
      mesage: "",
      bellModal: false,
      news_bell: [],
      news_bell_list: [],
      news_lenght: 20,
      count: "",
      column,
      workHome: [this.$route.fullPath],
      workKeys: [this.$route.fullPath],
      infoForm: {
        app_name: JSON.parse(localStorage.getItem('imgList')) ? JSON.parse(localStorage.getItem('imgList')).app_name : '' || '',
        login_introduction: JSON.parse(localStorage.getItem('imgList')) ? JSON.parse(localStorage.getItem('imgList')).login_introduction : '' || '',
        login_background_image: JSON.parse(localStorage.getItem('imgList')) ? JSON.parse(localStorage.getItem('imgList')).bgimg : '' || '',
        site_icon: JSON.parse(localStorage.getItem('imgList')) ? JSON.parse(localStorage.getItem('imgList')).icon : '' || '',
      },
      rpt_catalog: [], // 报表目录
      power: {
        mail_dept_list: '',//通讯录--部门管理--主数据
        mail_dept_weixi: '',//通讯录--部门管理--企业微信
        mail_mem_list: '',//通讯录--员工管理--主数据
        mail_mem_weixin: '',//通讯录--员工管理--企业微信
        quality_manage_initiate: '',//质量中心--质量管理--我发起的图审单
        quality_manage_check: '',//质量中心--质量管理--我校对的图审单
        quality_manage_assign: '',//质量中心--质量管理--我指派的图审单
        quality_manage_review: '',//质量中心--质量管理--我审核的图审单
        quality_manage_seal: '',//质量中心--质量管理--图纸盖章
        file_list_list: '',//文件中心--文件列表--列表
        file_list_template: '',//文件中心--文件列表--模板
        config_word_shortcuts: '',//应用配置--工作台配置--快捷方式
        config_word_pends: '',//应用配置--工作台配置--待办事项
        msg_mgmt_scene: '',//消息管理--消息管理--消息场景
        system_role_static: '',//系统管理--角色管理--系统角色
        system_role_dynamic: '',//系统管理--角色管理--项目角色
        system_role_dataaccs: '',//系统管理--角色管理--数据权限组
      }
    };
  },
  created() {
    // this.history.push(find(menusTileList, ['path', this.$route.path]));
    document.addEventListener("click", (e) => {
      // this.bellModal = false;
    });
  },
  destroyed() {
    // 销毁广播事件监听
    emitter.off("update-inner-message");
  },
  mounted() {
    this.getInfo()
    let _login_nm = store.state.user.login_nm;
    let _login_nms = JSON.parse(localStorage.getItem("user")).login_nm;
    let loginForm = JSON.parse(localStorage.getItem("user"));
    if (_login_nm != _login_nms) {
      this.$store.dispatch("user/login", loginForm.token);
    }
    if (this.$route.meta.activeMenuPath == undefined) {
      this.openKeys = [MenuKey(this.$route.fullPath)];
    } else if (this.$route.meta.activeMenuPath !== undefined) {
      this.openKeys = [MenuKey(this.$route.meta.activeMenuPath)];
    }
    this.handlerInfomation();
    // 初始化websocket连接
    // this.$store.dispatch('websocket/WEBSOCKET_INIT', process.env.VUE_APP_API_WEBSOCKET)
    // 销毁广播事件监听
    // emitter.off('update-inner-message')
    // 广播事件监听
    emitter.on("update-inner-message", () => {
      const _mesage = this.$store.state.websocket.message;
      this.openNotificationWithIcon(_mesage);
      this.handlerInfomation();
    });
    document.addEventListener('click', (e) => {
      let bell = document.getElementById('bellDiv')
      if (bell != null) {
        if ((!bell.contains(e.target))
          && !document.getElementById('news_icon').contains(e.target)) {
          this.bellModal = false
        }
      }
    })
    let that = this
    window.onresize = () => {
      return (() => {
        that.headerWidth = document.documentElement.clientWidth
        that.headerHeight = document.body.clientHeight
        // console.log(that.headerWidth);
      })()
    }
  },
  beforeMount() {
    this.handlerButton()
  },
  watch: {
    headerWidth(val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.headerWidth);
    }
  },
  provide() {
    return {
      handlerInfomation: this.handlerInfomation
    }
  },
  methods: {
    getItem(str, title) {//获得title的h4标签
      if (!title) {
        return str
      } else {
        let head = `<h4>${title}</h4>`
        return head + str;
      }
    },
    onOpenChange(openKeys) { // 当菜单被展开时触发此处
      /*  经测试传入的变量openKeys是数组 点击已经展开的菜单时传入的是空数组
          点击未展开的菜单时传入的是[当前展开菜单的key,点击的菜单key]
          下面的if判断用openKeys === [] 无效 所以我只能判断数组length是否等于0 */

      let latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
      let _menu = this.$store.state.user.menu
      let _menu_item = [];
      for (let i = 0; i < _menu.length; i++) {
        _menu_item.push(_menu[i].key);
      }
      if (_menu_item.indexOf(latestOpenKey) === -1) {
        this.openKeys = [openKeys[openKeys.length - 1]];
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    getWudthSider() {
      let set = this.$refs.siderWidth
      let sider_width = set.width
      return sider_width
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$store.dispatch("cache/delAllCachedViews");
      this.$router.push(`/login?redirect=${this.$route.fullPath}&loginstatus=logout`);//loginstatus=logout标识进行了退出登录操作
    },
    async modify() {
      await this.$store.dispatch("user/logout");
      this.$store.dispatch("cache/delAllCachedViews");
      this.$router.push(`/login?redirect=modify`);
    },
    refresh() {
      this.refreshAnimate = true;
      setTimeout(() => {
        this.refreshAnimate = false;
      }, 2000);
      this.handlerInfomation();
      this.$router.replace({ path: "/refresh" });
    },
    onBreakpoint(broken) {
      this.breakpoint = broken;
    },
    handlerInfomation() { // 消息
      $get({
        url: "auth/getUserInfo",
        params: {
          perpage: this.news_lenght
        },
      }).then((res) => {
        let result = res.data
        this.news_bell = result.news.list ? result.news.list : [];
        this.news_bell_list = this.news_bell
        if (this.news_bell_list.length > 0) {
          for (let i = 0; i < this.news_bell_list.length; i++) {
            this.news_bell_list[i].payload.message = md.render(this.news_bell_list[i].payload.message);
          }
        }
        this.count = this.news_bell_list.length > 0 ? result.news.pagination.total_items : 0;
      }).catch((err) => {
        console.log(err);
      });
    },
    handlerMoreInfomation() {
      this.news_lenght += 20
      this.handlerInfomation()
    },
    handlerClick(type, id) {
      $post({
        url: "auth/checkInnerNotice",
        params: {
          type: type,
          msg_id: id,
        },
      }).then((res) => {
        this.handlerInfomation();
      }).catch((err) => {
        console.log(err);
      });
    },
    handlerbell_message(type) {
      switch (type) {
        case type == "false":
          this.bellModal = true;
          break;
        default:
          this.bellModal = false;
          break;
      }
    },
    handlerMailDept(key) { // 部门管理
      if (key == 1) {
        this.$router.push({ name: "org-dept" });
      } else if (key == 2) {
        this.$router.push({ name: "wechat_index" });
      }
    },
    handlerMailMbr(key) { // 员工管理
      if (key == 1) {
        this.$router.push({ name: "org-mbr" });
      } else if (key == 2) {
        this.$router.push({ name: "wechat_mbr" });
      }
    },
    handlerQuality(key) { // 质量管理
      if (key == '1') {
        this.$router.push({ name: 'initiate_index' })
      } else if (key == '2') {
        this.$router.push({ name: 'proof_index' })
      } else if (key == '3') {
        this.$router.push({ name: 'assign_index' })
      } else if (key == '4') {
        this.$router.push({ name: 'process_index' })
      } else if (key == '5') {
        this.$router.push({ name: 'sealprj_index' })
      }
    },
    hangdlerNewsmanage(key) { // 消息管理
      if (key == "1") {
        this.$router.push({ name: "scene_index" });
      }
    },
    handlerFilemgm(key) { // 文件中心
      if (key == 1) {
        this.$router.push({ name: "file_index" });
      } else {
        this.$router.push({ name: "template_index" });
      }
    },
    handlerWorkbench(key) { // 快捷方式
      if (key == 1) {
        this.$router.push({ name: "shortcut" });
      } else {
        this.$router.push({ name: "pend" });
      }
    },
    handlerNewsJump(item) { // 消息详情
      if (item.payload.pth != null && item.payload.id != null) {
        if (this.$route.path != item.payload.pth) {
          // localStorage.setItem('detail_drawer', item.payload.id)
          this.$router.push({ path: item.payload.pth, params: item.payload.id });
          // this.bellModal = false;
        }
      }
    },
    openNotificationWithIcon(type) { // 消息弹框
      let news = this.$store.state.websocket.message;
      notification["success"]({
        message: news.title,
        description: (
          <div>
            <span> {news.msg} </span>
          </div>
        ),
        duration: 6,
      });
    },
    getInfo() {
      $get({
        url: 'auth/getConfigData',
      }).then(res => {
        let result = res.data;
        if (result.base != undefined) {
          let imgList = {}
          this.infoForm.app_name = result.base.app_name
          this.infoForm.login_introduction = result.base.login_introduction
          this.h5_text = result.base.login_introduction
          this.infoForm.login_background_image = result.base.login_background_image
          this.infoForm.site_icon = result.base.site_icon
          imgList.app_name = result.base.base;
          imgList.login_introduction = result.base.login_introduction;
          imgList.icon = result.base.site_icon;
          imgList.bgimg = result.base.login_background_image;
          localStorage.setItem('imgList', JSON.stringify(imgList))
          this.$store.dispatch('layout/changeAppname', this.infoForm.app_name)
          this.$store.dispatch('layout/changeSiteicon', this.infoForm.site_icon)
          let link = result.base.site_icon;
          let $favicon = document.querySelector('link[rel="icon"]');
          if ($favicon !== null) {
            $favicon.href = link;
          } else {
            $favicon = document.createElement("link");
            $favicon.rel = "icon";
            $favicon.href = link;
            document.head.appendChild($favicon);
          }
        }
      }).catch(err => {
        console.log(err);
      })
    },
    handlerButton() {
      this.power.mail_dept_list = hasAction(false, 'org-dept-masterdata')//通讯录--部门管理--主数据
      this.power.mail_dept_weixi = hasAction(false, 'org-dept-wecom')//通讯录--部门管理--企业微信
      this.power.mail_mem_list = hasAction(false, 'org-mbr-masterdata')//通讯录--员工管理--主数据
      this.power.mail_mem_weixin = hasAction(false, 'org-mbr-wecom')//通讯录--员工管理--企业微信
      this.power.quality_manage_initiate = hasAction(false, 'qc-mgmt-launch-draw-review')//质量中心--质量管理--我发起的图审单
      this.power.quality_manage_check = hasAction(false, 'qc-mgmt-proof-draw-review')//质量中心--质量管理--我校对的图审单
      this.power.quality_manage_assign = hasAction(false, 'qc-mgmt-assign-draw-review')//质量中心--质量管理--我指派的图审单
      this.power.quality_manage_review = hasAction(false, 'qc-mgmt-review-draw-review')//质量中心--质量管理--我审核的图审单
      this.power.quality_manage_seal = hasAction(false, 'qc-mgmt-seal')//质量中心--质量管理--图纸盖章
      this.power.file_list_list = hasAction(false, 'file-list-filelist')//文件中心--文件列表--列表
      this.power.file_list_template = hasAction(false, 'file-list-template')//文件中心--文件列表--模板
      this.power.config_word_shortcuts = hasAction(false, 'config-word-shortcuts')//应用配置--工作台配置--快捷方式
      this.power.config_word_pends = hasAction(false, 'config-word-pends')//应用配置--工作台配置--待办事项
      this.power.msg_mgmt_scene = hasAction(false, 'msg-mgmt-scene-view')//消息管理--消息管理--消息场景
      let maildept = []
      if (this.power.mail_dept_list) {
        maildept.push('1')
      }
      if (this.power.mail_dept_weixi) {
        maildept.push('2')
      }
      let mailmbr = []
      if (this.power.mail_mem_list) {
        mailmbr.push('1')
      }
      if (this.power.mail_mem_weixin) {
        mailmbr.push('2')
      }
      let manage = []
      if (this.power.quality_manage_initiate) {
        manage.push('1')
      }
      if (this.power.quality_manage_check) {
        manage.push('2')
      }
      if (this.power.quality_manage_assign) {
        manage.push('3')
      }
      if (this.power.quality_manage_review) {
        manage.push('4')
      }
      if (this.power.quality_manage_seal) {
        manage.push('5')
      }
      let filelist = []
      if (this.power.file_list_list) {
        filelist.push('1')
      }
      if (this.power.file_list_template) {
        filelist.push('2')
      }
      let configword = []
      if (this.power.config_word_shortcuts) {
        configword.push('1')
      }
      if (this.power.config_word_pends) {
        configword.push('2')
      }
      let msgmgmt = []
      if (this.power.msg_mgmt_scene) {
        msgmgmt.push('1')
      }
      if (this.$route.fullPath == '/mail/department') {
        this.handlerMailDept(maildept[0])
      } else if (this.$route.fullPath == '/mail/employee') {
        this.handlerMailMbr(mailmbr[0])
      } else if (this.$route.fullPath == '/auditorder/initiate') {
        this.handlerQuality(manage[0])
      } else if (this.$route.fullPath == '/newsmanage/scene') {
        this.hangdlerNewsmanage(msgmgmt[0])
      } else if (this.$route.fullPath == '/filemgm') {
        this.handlerFilemgm(filelist[0])
      } else if (this.$route.fullPath == '/appcfg/shortcuts') {
        this.handlerWorkbench(configword[0])
      }
    },
  },
  watch: {
    $route(e) { // 监听路由变化
      if (this.$route.path.indexOf("proj_home") < 0 && this.$route.path.indexOf("guide") < 0 && this.$route.path != '/login') {
        if (this.$route.meta.activeMenuPath != undefined) {
          this.op = [MenuKey(this.$route.meta.activeMenuPath)];
          this.openKeys = [MenuKey(this.$route.meta.activeMenuPath)];
        } else {
          this.op = [MenuKey(this.$route.path)];
          this.openKeys = [MenuKey(this.$route.path)];
        }
        this.selectedKeys = [e.path]
        this.$store.dispatch('layout/changeType', this.$store.state.layout.type)
      } else {
        this.$store.dispatch(
          "layout/changeTitle",
          this.$store.state.layout.toptitle
        );
      }
      this.workHome = [this.$route.path]
      if (e.query.loginstatus != 'logout') {
        this.handlerInfomation()
      }
      this.handlerButton()
      // console.log(this.$store.state.user);
    },
  },
};
</script>

<style lang="less">
@logo-height: 64px;
@header-height: 48px;

.refresh-animate {
  transform: rotate(360deg);
  transition: transform 1.5s;
}

#news_icon:hover {
  cursor: pointer;
}

#layout {
  min-height: 100vh;

  .logo {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 16px;
    line-height: 32px;
    cursor: pointer;
    background: #001529;

    img {
      display: inline-block;
      height: 32px;
      vertical-align: middle;
    }

    h1 {
      margin: 0 0 0 12px;
      overflow: hidden;
      color: #fff;
      // color: #000;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      -webkit-animation: fade-in;
      animation: fade-in;
      -webkit-animation-duration: 0.2s;
      animation-duration: 0.2s;
    }
  }

  .menu-box {
    height: calc(100vh - @logo-height);
    overflow-y: auto;
    background: #001529;

    .logo {
      position: relative;
      display: flex;
      align-items: center;
      padding: 16px 16px;
      line-height: 32px;
      cursor: pointer;

      img {
        display: inline-block;
        height: 32px;
        vertical-align: middle;
      }

      h1 {
        margin: 0 0 0 12px;
        overflow: hidden;
        color: #000;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        -webkit-animation: fade-in;
        animation: fade-in;
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
      }
    }
  }

  .ant-layout-header {
    background: #fff;
    padding: 0;
    height: auto;

    .layout-header {
      box-sizing: border-box;
      border-bottom: 1px solid rgba(245, 245, 245, 0.48);
      display: flex;
      align-items: center;
      height: @header-height;
      line-height: @header-height;
      padding: 0 24px 0 16px;
      background: #fff;

      .layout-header-left {
        flex: 1;
      }

      .layout-header-right {
        display: flex;
        align-items: center;
        position: relative;


        .cursor:hover {
          cursor: pointer;
          background: #e6f7ff;
        }
      }
    }

    .layout-subheader {
      // box-shadow: 0 1px 6px #f5f5f5;
      box-sizing: border-box;
      background: #fff;
      padding: 15px 32px 0;
      height: auto;

      .title {
        font-weight: bold;
        font-size: 20px;
      }
    }

    .button_top {
      position: absolute;
      top: 45px;
      right: 24px;
    }

    .anticon {
      font-size: 20px;
      margin-left: 16px;
    }
  }

  .ant-tabs {
    // background: #fff;

    .ant-tabs-bar {
      padding-top: 6px;

      .ant-tabs-nav {
        &>div {
          padding: 0 10px;
          font-weight: 500;

          .anticon {
            margin: 0;
            color: #a7a7a7;
          }
        }
      }
    }
  }

  .ant-list-split .ant-list-item {
    padding: 12px 24px;
  }

  .ant-tabs .ant-tabs-bar {
    padding: 0;
  }

  .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
    font-size: 14px;
  }

  .ant-tabs .ant-tabs-bar .ant-tabs-nav>div {
    padding: 0;
  }

  .ant-page-header-footer {
    margin: 0;
  }

  .ant-page-header-heading .ant-page-header-heading-title {
    margin-bottom: 20px;
  }

  .ant-list-item-meta-description {
    font-size: 12px;
    color: #000000a6;
  }

  .ant-menu-inline-collapsed>.ant-menu-item,
  .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
  .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
  .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
    left: 0;
    padding: 0 23px !important;
    text-overflow: clip;
  }

  .ant-menu-inline-collapsed {
    width: 60px;
  }

  .ant-page-header-footer .ant-tabs .ant-tabs-tab {
    font-size: 14px;
  }

  .bell_box {
    width: 350px;
    // height: auto;
    background: #fff;
    z-index: 999;
    border-radius: 5px;
    position: fixed;
    // position: absolute;
    top: 48px;
    right: 90px;
    box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 20%);

    .title_span {
      line-height: 50px;
      padding: 0 24px;
      color: #000;
      font-size: 16px;
      border-bottom: 1px solid #e8e8e8;
    }

    .bell_empty {
      padding: 24px;
    }

    .bell_bottom {
      border-top: 1px solid #e8e8e8;

      .bell_bottom_span {
        width: 50%;
        float: left;
        border-right: 1px solid #e8e8e8;
        text-align: center;
        color: #000;
      }

      .bell_bottom_span:hover {
        cursor: pointer;
        background: #e8e8e8;
      }
    }
  }
}

.flexbox {
  h4:first-child {
    font-size: 15px;
    color: #333;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1.5px;
      background-color: #f1f1f1;
      /* 设置线的颜色 */
      margin-top: 10px;
      /* 调整线与元素的距离 */
    }
  }

  h4:nth-child(2) {
    display: none;
  }

  blockquote {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Avenir, Helvetica, Arial, sans-serif;
      font-size: 11px;
      color: #5e5e5e;

      code {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        font-size: 11px;
        color: #333;
      }
    }

  }

  .toDetail {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #1890ff;
  }
}
</style>