<template>
  <a v-if="menu.path && menu.path.indexOf('http') > -1" :href="link()" class="a-link underline">{{ menu.title }}</a>
  <!-- <router-link v-else class="a-link" :to="{path: link(),query:query}"> {{ menu.title }} </router-link> -->
  <a v-else-if="type == undefined" class="a-link" @click="handlerMenu">{{ menu.title }}</a>
  <!-- <router-link v-else-if="type == undefined" class="a-link" :to="{ path: link() }">{{ menu.title }}{{ link() }}</router-link> -->
  <a v-else-if="type == '2'" class="a-link" @click="handlerJump">{{ menu.title }}</a>
  <!-- <router-link v-else-if="type == '2'" class="a-link" :to="{ path: link(), query: query }">{{ menu.title }}</router-link> -->
</template>

<script>
export default {
  name: "layout-link",
  props: {
    type: '',
    menu: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      query: JSON.parse(localStorage.getItem("query"))
    };
  },
  methods: {
    link() {
      if (this.menu.path) {
        let url = `${(this.menu.path.indexOf("/") < 0 || this.menu.path.indexOf("/") > 1) ? "/" : ""}${this.menu.path}`;
        if (this.menu.params) {
          url += "?";
          Object.keys(this.menu.params).forEach((value) => {
            url += (`${value}=${this.menu.params[value]}&`);
          });
          url = url.substring(0, url.length - 1);
        }
        return url;
      } else {
        return "/404";
      }
    },
    handlerMenu(){
      this.$router.push({
        path: this.link(),
      })
    },
    handlerJump() {
      this.$router.push({
        path: this.link(),
        query: this.query,
      })
    },
  },
  beforeMount() {
    // localStorage.setItem('query', JSON.stringify(this.$route.query) );
  },
};
</script>

<style scoped lang="less">
.a-link {
  display: inline;
}
</style>
<style lang="less">
.underline {
  text-decoration: underline;
}

.ant-menu-inline-collapsed {
  .a-link {
    display: none;
  }
}
</style>
