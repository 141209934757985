import { getToken } from "@/utils/auth";
import { emitter } from "@/utils";

let state = {
  websock: null,
  url: "",
  lockReconnect: false, //是否真正建立连接
  timeout: 6 * 1000, //10秒一次心跳
  // timeout: 10 * 1000, //10秒一次心跳
  timeoutObj: null, //心跳心跳倒计时
  serverTimeoutObj: null, //心跳倒计时
  timeoutnum: null, //断开 重连倒计时
  message: {},
};

const getters = {
  message(state) {
    return state.message;
  }
};

const mutations = {
  // websocket 
  WEBSOCKET_INIT(state, url) {
    var that = this;
    state.websock = new WebSocket(url);
    state.url = url;
    // 与websocket服务端连接成功
    state.websock.onopen = function() {
      // console.log("websocket连接成功") 
      //发送用户JWT令牌 后端解析后自动绑定用户
      const _token = getToken();
      //state.websock.send("JSON.stringify({msgType: "login",msgBody:kent>>>})")
      state.websock.send(JSON.stringify({ msgType: "login", msgBody: _token }));
      //console.log(JSON.stringify({msgType: "login",msgBody:_token}));
      //发送心跳包
      that.commit("websocket/pingpong")
    }
    // 接收到websocket服务端消息
    state.websock.onmessage = function (callback) {
      let _msg = JSON.parse(callback.data);
      // console.log(_msg);
      // 重置心跳
      that.commit("websocket/reset");
      // websocket服务端返回心跳消息,不作处理
      if (_msg.msgType == "heartbeat") {
        return;
      }
      state.message = _msg;
      emitter.emit("update-inner-message");
    }
    // 错误
    state.websock.onerror = function () {
      that.commit("websocket/reconnect")
    }
  },
  // 发送消息
  WEBSOCKET_SEND(state, message) {
    state.websock.send(message);
  },
  // 重新向websocket服务端发起连接
  reconnect(state) {
    var that = this;
    if (state.lockReconnect) {
      return ;
    }
    state.lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    state.timeoutnum && clearTimeout(state.timeoutnum);
    state.timeoutnum = setTimeout(function () {
      // 新连接
      that.commit("websocket/WEBSOCKET_INIT", state.url)
      state.lockReconnect = false;
    }, 5000);
  },
  // 重置心跳
  reset(state) {
    // 清除时间
    clearTimeout(state.timeoutObj);
    clearTimeout(state.serverTimeoutObj);
  },
  // 心跳消息
  pingpong(state) {
    var that = this;
    state.timeoutObj && clearTimeout(state.timeoutObj);
    state.serverTimeoutObj && clearTimeout(state.serverTimeoutObj);
    state.timeoutObj = setTimeout(function () {
      //这里发送一个心跳，后端收到后，返回一个心跳消息:{msgType: "heartbeat","msgBody":"pong"}
      if (state.websock.readyState == 1) {
        // 如果连接正常
        state.websock.send(
          JSON.stringify({ msgType: "heartbeat", msgBody: "ping"})
        );
      } else {
        // 重连
        that.commit("websocket/reconnect")
      }
      state.serverTimeoutObj = setTimeout(function () {
        // 超时关闭
        state.websock.close();
      }, state.timeout);
    }, state.timeout)
  }
};

const actions = {
  WEBSOCKET_INIT({ commit }, url) {
    commit("WEBSOCKET_INIT", url)
  },
  WEBSOCKET_SEND({ commit }, message) {
    commit("WEBSOCKET_SEND", message)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};