const state = {
  title: "",
  type: "",
  newskey: "",
  status: "",
  toptitle: "",
  director: "",
  projstatus: "",
  path: "",
  appname: "",
  siteicon: "",
  topmenu: "",
};

const mutations = {
  CHANGETITLE: (state, title) => {
    state.title = title;
  },
  CHANGETYPE: (state, type) => {
    state.type = type;
  },
  CHANGENEWSKEY: (state, newskey) => {
    state.newskey = newskey;
  },
  CHANGESTATUS: (state, status) => {
    state.status = status;
  },
  CHANGETOPTITLE: (state, toptitle) => {
    state.toptitle = toptitle;
  },
  CHANGEDIRECTOR: (state, director) => {
    state.director = director;
  },
  CHANGEPROJSTATUS: (state, projstatus) => {
    state.projstatus = projstatus;
  },
  CHANGEPATH: (state, path) => {
    state.path = path;
  },
  CHANGEAPPNAME: (state, appname) => {
    state.appname = appname;
  },
  CHANGESITEICON: (state, siteicon) => {
    state.siteicon = siteicon;
  },
  CHANGETOPMENU: (state, topmenu) => {
    state.topmenu = topmenu;
  },
};

const actions = {
  changeTitle({ commit }, title) {
    commit("CHANGETITLE", title);
  },
  changeType({ commit }, type) {
    commit("CHANGETYPE", type);
  },
  changeNewskey({ commit }, newskey) {
    commit("CHANGENEWSKEY", newskey);
  },
  changeStatus({ commit }, status) {
    commit("CHANGESTATUS", status);
  },
  changeToptitle({ commit }, toptitle) {
    commit("CHANGETOPTITLE", toptitle);
  },
  changeDirector({ commit }, director) {
    commit("CHANGEDIRECTOR", director);
  },
  changeProjstatus({ commit }, projstatus) {
    commit("CHANGEPROJSTATUS", projstatus);
  },
  changePath({ commit }, path) {
    commit("CHANGEPATH", path);
  },
  changeAppname({ commit }, appname) {
    commit("CHANGEAPPNAME", appname);
  },
  changeSiteicon({ commit }, siteicon) {
    commit("CHANGESITEICON", siteicon);
  },
  changeTopmenu({ commit }, topmenu) {
    commit("CHANGETOPMENU", topmenu);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
