import { createApp } from "vue";
import NProgress from "nprogress";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/antd.css";
import "nprogress/nprogress.css";
import '@wangeditor/editor/dist/css/style.css' // 引入 css
// import echarts from 'echarts'
import * as echarts from 'echarts'
import 'babel-polyfill'
import dataV from '@jiaminghi/data-view'
import { Input, Select, message } from 'ant-design-vue'

//全局处理message输出内容不规范问题
message.originalOpen = message.open;
message.open = (args) => {
  // 判断 args.content 是否为字符串
  if (typeof args.content !== 'string') {
    args.content = '未定义的消息提示，请联系管理员添加';
  }
  // 调用重命名后的方法
  message.originalOpen(args);
};

Input.props.allowClear = { type: Boolean, default: true } //为true时输入框显示清除图标
// Select.props.allowClear = { type: Boolean, default: true } //为true时选择框显示清除图标

const app = createApp(App);

NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});
app.config.productionTip = false;
// app.prototype.$echarts = echarts
app
  .use(Antd)
  .use(store)
  .use(router)
  .use(echarts)
  .use(dataV)
// app.config.globalProperties({
//   render: (h) => h(App),
// });
app.mount("#app");
