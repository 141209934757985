export default [ // 财务管理
    {   // 财务管理--客户结算
        path: '/finance/clientsettle',
        name: 'finance_clientsettle',
        component: (() => import('@/views/finance/clientsettle/index.vue')),
        meta: { title: "客户结算", type: 'clientsettle' },
    },
    {   // 财务管理--客户结算
        path: '/finance/property',
        name: 'finance_property',
        component: (() => import('@/views/finance/property/index.vue')),
        meta: { title: "产权分配", type: 'property' },
    },
    //供应商结算
    {   // 财务管理--客户结算
        path: 'finance/vendorsettle',
        name: 'finance_vendorsettle',
        component: (() => import('@/views/finance/vendorsettle/index.vue')),
        meta: { title: "供应商结算", type: 'vendorsettle' },
    },
    {   // 财务管理--采购对账
        path: 'finance/purchaseaccount',
        name: 'finance_purchaseaccount',
        component: (() => import('@/views/finance/purchaseaccount/index.vue')),
        meta: { title: "采购对账", type: 'purchaseaccount' },
    },
];