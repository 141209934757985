export default {
    path: '/proj_home',
    name: 'topmenu',
    component: (() => import('@/views/topMenu/index.vue')),
    meta: { title: '', type: 'prjspace' },
    children: [
        {   // 项目首页无权限
            path: '/proj_home/403',
            name: 'projhome_403',
            component: (() => import('@/views/top_menu/components/index/403.vue')),
            meta: { title: '项目首页', type: 'prjspace' },
        },
        {   // 项目首页
            path: '/proj_home/dashboard',
            name: 'project_home',
            component: (() => import('@/views/top_menu/home/index.vue')),
            meta: { title: '项目首页', type: 'prjspace' },
        },
        {   // 项目进度--项目计划
            path: '/proj_home/progress',
            name: 'progress_index',
            component: (() => import('@/views/top_menu/progress/index.vue')),
            meta: { title: '项目计划', type: 'prjspace' },
        },
        {   // 项目成员--设计成员
            path: '/proj_home/member/design',
            name: 'proj_member',
            component: (() => import('@/views/top_menu/member/design/index.vue')),
            meta: { title: '设计成员', type: 'prjspace' },
        },
        {   // 项目成员--服务成员
            path: '/proj_home/member/service',
            name: 'serve_member',
            component: (() => import('@/views/top_menu/member/service/index.vue')),
            meta: { title: '服务成员', type: 'prjspace' },
        },
        {   // 项目成员
            path: '/proj_home/projmember',
            name: 'proj_members',
            component: (() => import('@/views/top_menu/member/index.vue')),
            meta: { title: '项目成员', type: 'prjspace' },
        },
        {   // 项目成员--外部联系人
            path: '/proj_home/contacts',
            name: 'deparcontacts',
            component: (() => import('@/views/top_menu/contact/index.vue')),
            meta: { title: '外部联系人', type: 'prjspace' },
        },
        {   // 需求台账
            path: '/proj_home/design/req',
            name: 'ledgerIndex',
            component: (() => import('@/views/top_menu/design/req/index.vue')),
            meta: { title: '需求台账', type: 'prjspace' },
        },
        {   // 项目任务--设计任务
            path: '/proj_home/design/task',
            name: 'taskinfo',
            component: (() => import('@/views/top_menu/task/task/index.vue')),
            meta: { title: '设计任务', type: 'prjspace' },
        },
        {   // 项目任务--联系单
            path: '/proj_home/service/order',
            name: 'catalogContactindex',
            component: (() => import('@/views/top_menu/task/order/index.vue')),
            meta: { title: '联系单', type: 'prjspace' },
        },
        {   // 项目活动--设计交付
            path: '/proj_home/design/delivery',
            name: 'deliver',
            component: (() => import('@/views/top_menu/design/delivery/index.vue')),
            meta: { title: '设计交付', type: 'prjspace' },
        },
        {   // 项目活动--设计归档
            path: '/proj_home/design/arch',
            name: 'archivingindex',
            component: (() => import('@/views/top_menu/design/arch/index.vue')),
            meta: { title: '设计归档', type: 'prjspace' },
        },
        {   // 项目活动--图纸交底
            path: '/proj_home/service/delivery',
            name: 'drawing',
            component: (() => import('@/views/top_menu/design/disclose/index.vue')),
            meta: { title: '图纸交底', type: 'prjspace' },
        },
        {   // 项目活动--施工答疑
            path: '/proj_home/service/qa',
            name: 'construction',
            component: (() => import('@/views/top_menu/design/qa/index.vue')),
            meta: { title: '施工答疑', type: 'prjspace' },
        },
        {   // 项目活动--现场巡检
            path: '/proj_home/service/inspection',
            name: 'inspection',
            component: (() => import('@/views/top_menu/design/inspection/index.vue')),
            meta: { title: '现场巡检', type: 'prjspace' },
        },
        {   // 项目活动--竣工验收
            path: '/proj_home/service/acceptance',
            name: 'accept',
            component: (() => import('@/views/top_menu/design/acceptance/index.vue')),
            meta: { title: '竣工验收', type: 'prjspace' },
        },
        {   // 项目活动--服务归档
            path: '/proj_home/service/arch',
            name: 'servearch',
            component: (() => import('@/views/top_menu/design/servicearch/index.vue')),
            meta: { title: '服务归档', type: 'prjspace' },
        },
        {   // 项目质量--图纸内审
            path: '/proj_home/quality/in_audit',
            name: 'auditindex',
            component: (() => import('@/views/top_menu/quality/in_audit/index.vue')),
            meta: { title: '图纸内审', type: 'prjspace' },
        },
        {   // 项目质量--图纸外审
            path: '/proj_home/quality/ext_audit',
            name: 'outreview',
            component: (() => import('@/views/top_menu/quality/ext_audit/index.vue')),
            meta: { title: '图纸外审', type: 'prjspace' },
        },
        {   // 项目质量--图纸光评
            path: '/proj_home/quality/light_audit',
            name: 'rayreview',
            component: (() => import('@/views/top_menu/quality/light_audit/index.vue')),
            meta: { title: '图纸光评', type: 'prjspace' },
        },
        {   // 项目质量--图纸安评
            path: '/proj_home/quality/safe_audit',
            name: 'secreview',
            component: (() => import('@/views/top_menu/quality/safe_audit/index.vue')),
            meta: { title: '图纸安评', type: 'prjspace' },
        },
        {   // 项目质量--项目出图
            path: '/proj_home/printing',
            name: 'plotindex',
            component: (() => import('@/views/top_menu/quality/printing/index.vue')),
            meta: { title: '项目出图', type: 'prjspace' },
        },
        {   // 项目质量--图纸盖章
            path: '/proj_home/quality/seal',
            name: 'top_seal_index',
            component: (() => import('@/views/top_menu/quality/seal/index.vue')),
            meta: { title: '图纸盖章', type: 'prjspace' },
        },
        {   // 项目绩效-楼栋信息
            path: '/proj_home/performance/buildinginfo',
            name: 'buildinginfo',
            component: (() => import('@/views/top_menu/performance/buildinginfo/index.vue')),
            meta: { title: '楼栋信息', type: 'prjspace' },
        },
        {   // 项目绩效--项目面积
            path: '/proj_home/area',
            name: 'area_index',
            component: (() => import('@/views/top_menu/performance/area/index.vue')),
            meta: { title: '项目面积', type: 'prjspace' },
        },
        {   // 项目绩效--项目分
            path: '/proj_home/performance/projectpoints',
            name: 'projectpoints',
            component: (() => import('@/views/top_menu/performance/projectpoints/index.vue')),
            meta: { title: '项目分', type: 'prjspace' },
        },
        {   // 项目绩效--协议变更
            path: '/proj_home/performance/treaty',
            name: 'treaty_index',
            component: (() => import('@/views/top_menu/performance/treaty/index.vue')),
            meta: { title: '协议变更', type: 'prjspace' },
        },
        {   // 项目文件
            path: '/proj_home/file',
            name: 'depardocument',
            component: (() => import('@/views/top_menu/file/index.vue')),
            meta: { title: '项目文件', type: 'prjspace' },
        },
        {   // 项目日程
            path: '/proj_home/meeting',
            name: 'meet',
            component: (() => import('@/views/top_menu/schedule/index.vue')),
            meta: { title: '项目日程', type: 'prjspace' },
        },
        {   // 项目事件
            path: '/proj_home/event',
            name: 'event_index',
            component: (() => import('@/views/top_menu/event/index.vue')),
            meta: { title: '项目事件', type: 'prjspace' },
        },
        {   // 权限配置
            path: '/proj_home/power',
            name: 'power_index',
            component: (() => import('@/views/top_menu/power/index.vue')),
            meta: { title: '权限配置', type: 'prjspace' },
        },
        {   // 项目启动会
            path: '/proj_home/startmeet',
            name: 'startmeet_index',
            component: (() => import('@/views/top_menu/design/startmeet/index.vue')),
            meta: { title: '项目启动会', type: 'prjspace' },
        },
        {   // 项目结束会
            path: '/proj_home/endmeet',
            name: 'endmeet_index',
            component: (() => import('@/views/top_menu/design/endmeet/index.vue')),
            meta: { title: '项目结束会', type: 'prjspace' },
        },
        {   // 项目复盘会
            path: '/proj_home/reviewmeet',
            name: 'reviewmeet_index',
            component: (() => import('@/views/top_menu/design/reviewmeet/index.vue')),
            meta: { title: '项目复盘会', type: 'prjspace' },
        },
        {   // 项目结算
            path: '/proj_home/settlement',
            name: 'settlement',
            component: (() => import('@/views/top_menu/settlement/index.vue')),
            meta: { title: '项目结算', type: 'prjspace' },
        },
        {   //项目活动（暂时废弃）
            path: '/proj_home/activity',
            name: "activity",
            component: (() => import('@/views/top_menu/activity/index.vue')),
            meta: { title: '项目活动', type: 'prjspace' },
        },
        {   //交付管理
            path: '/proj_home/deliverymgt',
            name: "deliverymgt",
            component: (() => import('@/views/top_menu/projdelivery/deliverymgt/index.vue')),
            meta: { title: '交付管理', type: 'prjspace' },
        },
        {   //成果管理
            path: '/proj_home/resultmgt',
            name: "resultmgt",
            component: (() => import('@/views/top_menu/projdelivery/resultmgt/index.vue')),
            meta: { title: '成果管理', type: 'prjspace' },
        },
        {   //关联订单
            path: '/proj_home/order',
            name: "proj_home_order",
            component: (() => import('@/views/top_menu/order/index.vue')),
            meta: { title: '关联订单', type: 'prjspace' },
        }
    ],
};
