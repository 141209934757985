export default [ // 设计中心
    {   // 设计中心--设计项目
        path: '/companproj_index',
        name: 'companyindex',
        component: (() => import('@/views/designprj/project/index.vue')),
        meta: { title: '设计项目', type: 'companyindex' },
        // redirect: '/companproj_index/partakeproj',
        children: [
            {
                path: '/companproj_index/partakeproj',
                name: 'join_index',
                component: (() => import('@/views/designprj/project/join_index.vue')),
                meta: { title: '设计项目', type: 'companyindex' },
            },
            {
                path: '/companproj_index/responsibleproj',
                name: 'responsible_index',
                component: (() => import('@/views/designprj/project/responsible_index.vue')),
                meta: { title: '设计项目', type: 'companyindex' },
            },
            {
                path: '/companproj_index/deptmentproj',
                name: 'deptment_index',
                component: (() => import('@/views/designprj/project/deptment_index.vue')),
                meta: { title: '设计项目', type: 'companyindex' },
            },
            {
                path: '/companproj_index/deptmentsubproj',
                name: 'deptmentsub_index',
                component: (() => import('@/views/designprj/project/deptmentsub_index.vue')),
                meta: { title: '设计项目', type: 'companyindex' },
            },
            {
                path: '/companproj_index/companyproj',
                name: 'company_index',
                component: (() => import('@/views/designprj/project/compant_index.vue')),
                meta: { title: '设计项目', type: 'companyindex' },
            },
            // {
            //     path: '/companproj_index/responsible',
            //     name: 'company_sub_index',
            //     component: (() => import('@/views/designprj/project/sublist.vue')),
            //     meta: { title: '设计项目', type: 'companyindex' },
            // },
        ],
    },
    {   // 设计中心--设计任务
        path: "/designtask",
        name: "tasking",
        component: (() => import('@/views/designprj/task/tasking.vue')),
        meta: { title: '设计任务', type: 'list' },
    },
    {   // 设计中心--联系单
        path: "/ordertask",
        name: "conlistindex",
        component: (() => import('@/views/designprj/contact/conlistindex.vue')),
        meta: { title: '联系单', type: 'list' },
    },
]