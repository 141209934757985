<template>
    <div>
        <!-- 我的 -->
        <HomeOutlined v-if="icon == 'home'" />
        <!-- 通讯录 -->
        <TeamOutlined v-else-if="icon == 'team'" />
        <!-- 项目管理中心 -->
        <ProjectOutlined v-else-if="icon == 'project'" />
        <!-- 设计中心 -->
        <AlignCenterOutlined v-else-if="icon == 'align-center'" />
        <!-- 项目库 -->
        <ContainerOutlined v-else-if="icon == 'container'" />
        <!-- 质量中心 -->
        <FileSearchOutlined v-else-if="icon == 'file-search'" />
        <!-- 图文中心 -->
        <PrinterOutlined v-else-if="icon == 'printer'" />
        <!-- 审批中心 -->
        <AuditOutlined v-else-if="icon == 'audit'" />
        <!-- 绩效中心 -->
        <HourglassOutlined v-else-if="icon == 'hourglass'" />
        <!-- 文件中心 -->
        <FileOutlined v-else-if="icon == 'file'" />
        <!-- 外部协作 -->
        <!-- <ProjectOutlined v-else-if="icon == 'team'" /> -->
        <!-- 订单管理 -->
        <OrderedListOutlined v-else-if="icon == 'ordered-list'" />
        <!-- 合同管理 -->
        <FileProtectOutlined v-else-if="icon == 'file-protect'" />
        <!-- 财务管理 -->
        <PayCircleOutlined v-else-if="icon == 'pay-circle'" />
        <!-- 商业智能 -->
        <GlobalOutlined v-else-if="icon == 'global'" />
        <!-- 消息管理 -->
        <MessageOutlined v-else-if="icon == 'message'" />
        <!-- 应用配置 -->
        <AppstoreOutlined v-else-if="icon == 'appstore'" />
        <!-- 权限管理 -->
        <LockOutlined v-else-if="icon == 'lock'" />
        <!-- 系统管理 -->
        <SettingOutlined v-else-if="icon == 'setting'" />
        <!-- 企业管理 -->
        <ToolOutlined v-else-if="icon == 'tool'" />
        <MoneyCollectOutlined v-else-if="icon == 'money-collect'" />
        <!-- 订单管理 -->
        <BlockOutlined v-else-if="icon == 'block'" />
    </div>
</template>

<script>
import {
    HomeOutlined,
    TeamOutlined,
    ProjectOutlined,
    AlignCenterOutlined,
    ContainerOutlined,
    FileSearchOutlined,
    PrinterOutlined,
    AuditOutlined,
    HourglassOutlined,
    FileOutlined,
    OrderedListOutlined,
    FileProtectOutlined,
    PayCircleOutlined,
    GlobalOutlined,
    MessageOutlined,
    AppstoreOutlined,
    LockOutlined,
    SettingOutlined,
    ToolOutlined,
    MoneyCollectOutlined,
    BlockOutlined,
} from "@ant-design/icons-vue";
export default {
    name: 'icon_index',
    components: {
        HomeOutlined,
        TeamOutlined,
        ProjectOutlined,
        AlignCenterOutlined,
        ContainerOutlined,
        FileSearchOutlined,
        PrinterOutlined,
        AuditOutlined,
        HourglassOutlined,
        FileOutlined,
        OrderedListOutlined,
        FileProtectOutlined,
        PayCircleOutlined,
        GlobalOutlined,
        MessageOutlined,
        AppstoreOutlined,
        LockOutlined,
        SettingOutlined,
        ToolOutlined,
        MoneyCollectOutlined,
        BlockOutlined
    },
    props: {
        icon: '',
    },
    data() {
        return {};
    },
    mounted() {
        // console.log(this.icon);
    },
}
</script>

<style></style>