export default [ // 质量中心
    {   // 质量中心--质量管理
        path: '/auditorder',
        name: 'auditorder_index',
        component: (() => import('@/views/quality/company/index.vue')),
        meta: { title: '质量管理', type: 'auditorder' },
        redirect: '/auditorder/initiate',
        children: [
            { // 质量管理--我发起的图审单
                path: '/auditorder/initiate',
                name: 'initiate_index',
                component: (() => import('@/views/quality/company/initiate/index.vue')),
                meta: { title: '质量管理', type: 'auditorder' },
            },
            { // 质量管理--我校对的图审单
                path: '/auditorder/drawproof',
                name: 'proof_index',
                component: (() => import('@/views/quality/company/proof/index.vue')),
                meta: { title: '质量管理', type: 'auditorder' },
            },
            { // 质量管理--我指派的图审单
                path: '/auditorder/assign',
                name: 'assign_index',
                component: (() => import('@/views/quality/company/assign/index.vue')),
                meta: { title: '质量管理', type: 'auditorder' },
            },
            { // 质量管理--我审核的图审单
                path: '/auditorder/process',
                name: 'process_index',
                component: (() => import('@/views/quality/company/process/index.vue')),
                meta: { title: '质量管理', type: 'auditorder' },
            },
            { // 质量管理--图纸盖章
                path: '/auditorder/drawseal',
                name: 'sealprj_index',
                component: (() => import('@/views/quality/sealprj/index.vue')),
                meta: { title: '质量管理', type: 'auditorder' },
            }
        ]
    },
];