import { $get, baseURL } from "@/utils/request";
import { message } from "ant-design-vue";
import store from "@/store";

export const getUserInfo = function (callback) {
  // data:返回的数据
  // errmsg: // 提示信息
  //   data :// 数据
  //     login_nm：登录名
  // nm:用户名称
  // nn:用户昵称
  // status:用户帐号状态
  // locked:用户帐号是否被锁定
  // acct_nm:用户所属部门
  // mob:用户手机
  // org_nm：用户所属公司
  // gender:性别。1：男；2：女;0：未知
  // avatar：头像；为空根据用户性别显示默认图像
  // menus:显示的菜单
  // uri:
  //   title:
  //     icon:
  //       children:
  //         uri:
  //           title:
  //             path:
  //               auth:页面权限
  $get({
    url: "auth/getUserInfo",
  }).then((res) => {
    callback(res.data);
  }).catch((err) => {
    console.log(err);
    message.error("用户信息获取失败")
  });
};

export const getConfigData = function () {
  // $get({
  //   url: "auth/getConfigData",
  // }).then((res) => {
  //   let result = res.data;
  //   store.dispatch('layout/changeAppname', result.base.app_name)
  //   store.dispatch('layout/changeSiteicon', result.base.site_icon)
  //   callback(res.data)
  // }).catch((err) => {
  //   console.log(err);
  // })
  return new Promise((resolve) => {
    $get({
      url: "auth/getConfigData",
    }).then(res => {
      let result = res.data;
      store.dispatch('layout/changeAppname', result.base.app_name)
      store.dispatch('layout/changeSiteicon', result.base.site_icon)
      resolve(res.data);
    }).catch(err => {
      message.error(err, 4);
    })
  })
};