export default [ // 工作台
    {   //工作台
        path: '/home/workspace',
        name: 'home_work',
        component: (() => import('@/views/home/index.vue')),
        meta: { type: "workspace" },
        redirect: '/home/workspace/work',
        children: [
            {   // 首页
                path: '/home/workspace/work',
                name: 'work',
                component: (() => import('@/views/home/work/index.vue')),
                meta: { title: "工作台", type: 'workspace' },
            },
            {   // 日程
                path: '/home/workspace/my_listview',
                name: 'my_listview',
                component: (() => import('@/views/home/schedule/index.vue')),
                meta: { title: "工作台", type: 'workspace' },
            },
            {   // 消息
                path: '/home/workspace/news',
                name: 'home_news',
                component: (() => import('@/views/home/news/index.vue')),
                meta: { title: "工作台", type: 'workspace' },
            },
            {   // 绩效
                path: '/home/workspace/performance',
                name: 'home_performance',
                component: (() => import('@/views/home/performance/index.vue')),
                meta: { title: "工作台", type: 'workspace' },
            },
            {   // 报表
                path: '/home/workspace/report',
                name: 'home_report',
                component: (() => import('@/views/home/report/index.vue')),
                meta: { title: '工作台', type: 'workspace' },
            },
        ],
    },
    {   // 个人设置
        path: "/setup",
        name: "setup",
        component: (() => import("@/views/setup/index.vue")),
        meta: { title: '个人设置', type: 'setup' },
    },
    {   // 企业设置
        path: "/entersetup",
        name: "enter_setup",
        component: (() => import("@/views/setup/entersetup")),
        meta: { title: '企业设置' },
    },
    // 横向菜单
    {   // 项目活动--设计归档--新建
        path: '/proj_home/design/arch/newindex',
        name: 'archincrease',
        component: (() => import('@/views/top_menu/design/arch/newindex.vue')),
        meta: { title: '设计归档', type: '' },
    },
    {   // 项目活动--设计归档--编辑
        path: '/proj_home/design/arch/editindex',
        name: 'archivingedit',
        component: (() => import('@/views/top_menu/design/arch/editindex.vue')),
        meta: { title: '设计归档', type: '' },
    },
];