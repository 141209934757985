import axios from "axios";
import qs from "qs";
import store from "@/store";
import { message } from "ant-design-vue";
export const baseURL = process.env.VUE_APP_API_URL;
export const basePORTA = process.env.VUE_APP_PORTAL_URL;
// export const baseURL = "https://dev.qtscm.net/cxa/ever/";

const instance = axios.create({ baseURL });
// instance.interceptors.request.use(
//   (config) => {
//     config.headers.Authorization = store.state.user.token;
//     console.log(config)
//     return config;
//   },
//   (error) => Promise.reject(error),
// );



let lastRequest = {
  params: null,
  path: null,
  timestamp: 0
};

instance.interceptors.request.use(function (config) {
  const currentTime = Date.now();
  const timeDifference = currentTime - lastRequest.timestamp;
  if (timeDifference < 3000 && JSON.stringify(lastRequest.data) === JSON.stringify(config.data) && lastRequest.path === config.url && config.method == 'post') {
    // 阻止请求
    return Promise.reject(('Request Interception'));
  }
  // 记录当前请求的参数、路径和时间戳
  lastRequest.data = config.data;
  lastRequest.path = config.url;
  lastRequest.timestamp = currentTime;
  config.headers.Authorization = store.state.user.token;
  return config;
});




// const errMsg = "系统开小差了！请稍后再试~";
const url_data = ['auth/login', '/wxbizauth/authSignin', '/auth/getConfigData', 'auth/getVerCode', '/wxbizauth/ackLoginInDev', 'auth/lic']
//                登录接口          企业微信获取token          企业设置              手机号获取验证码     企业微信回调地址
const errMsg = "对不起，您没有权限访问";
export const $post = function ({ url, params, workspace = false }) {
  if (store.state.user.token == '' && url_data.indexOf(url) == -1) {
    return new Promise((resolve, reject) => {
      resolve({ data: { data: false } })
    });
  } else {
    let newType = {};
    if (workspace == true) {
      let query = JSON.parse(localStorage.getItem("query"));
      newType = {
        project_id: query.id == undefined ? query.sub_id : query.id,
        idType: query.id == undefined ? "1" : "0",
        ...params,
      }
    } else {
      newType = {
        ...params,
      }
    }
    return new Promise((resolve, reject) => {
      instance
        .post(url, qs.stringify(newType))
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code == true) {
              resolve(res.data.data);
            } else {
              reject(res.data.data.errmsg);
            }
          } else {
            reject(res.statusText || res.data.message || errMsg);
          }
        })
        .catch((err) => {
          if (err == 'Request Interception') {
            message.warning('请勿重复提交')
          } else {
            let obj = typeof err;
            if (obj == "object") {
              let { message } = err;
              if (message == "Network Error") {
                reject("当前系统离线，请确保网络连接正常");
              } else {
                reject("系统未识别的错误，请联系管理员添加该错误类型");
              };
            } else {
              reject(err);
            }
          }
        });
    });
  }

};
export const $get = function ({ url, params, workspace = false }) {
  //判断请求后端接口前是否有token,token为空时不请求后端接口
  if (store.state.user.token == '' && url_data.indexOf(url) == -1) {
    return new Promise((resolve, reject) => {
      resolve({ data: { data: false } })
    });
  } else {
    let newType = {}
    if (workspace == true) {
      let query = JSON.parse(localStorage.getItem("query"))
      newType = {
        project_id: query.id == undefined ? query.sub_id : query.id,
        idType: query.id == undefined ? "1" : "0",
      }
      params = {
        ...params,
        ...newType,
      }
    } else {
      params = {
        ...params,
        ...newType,
      }
    }
    return new Promise((resolve, reject) => {
      const _headers = {
        headers: {
          Authorization: store.state.user.token,
        },
      };
      const _params = params ? { params, ..._headers, ...newType } : { ..._headers, ...newType };
      instance
        .get(url, _params)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code == true) {
              resolve(res.data.data);
            } else {
              reject(res.data.data.errmsg);
            }
          } else {
            reject(res.statusText || res.data.message || errMsg);
          }
        })
        .catch((err) => {
          let obj = typeof err;
          if (obj == "object") {
            let { message } = err;
            if (message == "Network Error") {
              reject("当前系统离线，请确保网络连接正常");
            } else {
              reject("系统未识别的错误，请联系管理员添加该错误类型");
            }
          } else {
            reject(err);
          }
        });
    });
  }

};
export const $req = function ({ requestType, requestUrl, requestParams }) {
  return new Promise((resolve, reject) => {
    requestType = requestType.toUpperCase();
    if (requestType === "GET") {
      $get({
        url: requestUrl,
        params: requestParams,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      $post({
        url: requestUrl,
        params: requestParams,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};
// 文件下载
export const $download = function (resourceId, resourceName) {
  const formData = new FormData();
  let query = JSON.parse(localStorage.getItem("query"))
  formData.append("rsc_id", resourceId);
  formData.append("project_id", query.id == undefined ? query.sub_id : query.id);
  formData.append("idType", query.id == undefined ? "1" : "0");
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: baseURL + "projfile/down_file",
      data: formData,
      headers: {
        Authorization: store.state.user.token
      },
      responseType: "blob",
    }).then((res) => {
      if (res.request.status == '403') {
        message.error('非文件的归属人，未被授予该文件的操作权限！')
      } else {
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", resourceName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      resolve(false)
    }).catch((err) => {
      if (err.request.status == '0') {
        message.error('非文件的归属人，未被授予该文件的操作权限！')
      }
      reject(err);
    });
  })
};

export const $downleftload = function (resourceId, resourceName) {
  const formData = new FormData();
  formData.append("rsc_id", resourceId);
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: baseURL + "file/down_file",
      data: formData,
      headers: {
        Authorization: store.state.user.token
      },
      responseType: "blob",
    }).then((res) => {
      let url = window.URL.createObjectURL(new Blob([res.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", resourceName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve(false)
    }).catch((err) => {
      console.log(err);
      reject(err);
    });
  })
};

//判断是否是企业微信浏览器
export function isWxBrowser() {
  // let ua = navigator.userAgent.toLowerCase();
  // let isWx = ua.match(/MicroMessenger/i) == "micromessenger";
  // let isWxWork = ua.match(/WxWork/i) == "wxwork";
  // alert(ua)
  // alert(ua.match(/MicroMessenger/i))
  // if (isWx && isWxWork) {
  //   return true
  // } else {
  //   return false
  // }
  var isMobile = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i); // 是否手机端
  var isWx = /micromessenger/i.test(navigator.userAgent); // 是否微信
  var isComWx = /wxwork/i.test(navigator.userAgent); // 是否企业微信
  // alert('isMobile:' + isMobile + 'isWx:' + isWx + 'iscomWx:' + isComWx)
  if (isComWx && isMobile) { //手机端企业微信
    return true
    // alert('手机端企业微信')
    // return 'com-wx-mobile'
  }
  else if (isComWx && !isMobile) { //PC端企业微信
    return true
    // alert('PC端企业微信')
    // return 'com-wx-pc'
  }
  else if (isWx && isMobile) { // 手机端微信
    return true
    // alert('手机端微信')
    // return 'wx-mobile';
  }
  else if (isWx && !isMobile) { // PC端微信
    return true
    // alert('PC端微信')
    // return 'wx-pc';
  }
  else {
    return false
    // return 'other';
  }
}

export function randomString() {
  //随机生成字符串
  let list = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"
  let maxPos = list.length
  let pwd = ""
  for (let i = 0; i < 16; i++) {
    pwd += list.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}